button {
    background-color: $success-color;
    border-radius: 2px;
    border: 0;
    color: $white-color;
    text-align: center;
    padding: $spacing-unit/3 $spacing-unit/2;
    text-transform: uppercase;
    font-family: $soft-font-family;
    letter-spacing: 2px;
    @extend %transition;
    cursor: pointer;

    &:hover, &:focus, &:active {
        background-color: $brand-color;
        color: $white-color;
        text-decoration: none;
        box-shadow: 0 3px 8px 0 rgba(black, .5);
        transform: translateY(-1px); }


    &.btn {
        &-link {
         background-color: transparent;
         padding: 0;

         &:hover, &:focus, &:active {
             box-shadow: none; } } } }
