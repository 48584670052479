@include module(modal) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    margin: auto;
    background-color: rgba(#000, .6);
    animation: fade-in .3s ease-in-out forwards;
    padding: $spacing-unit * 2;
    display: flex;
    justify-content: center;
    color: black;

    @include media-query($on-laptop) {
        padding: $spacing-unit; }

    > * {
        position: relative;
        // padding: $spacing-unit + ($spacing-unit/2) $spacing-unit 0 $spacing-unit
        padding: $spacing-unit/2 $spacing-unit;
        border-radius: 4px;

        @include media-query($on-palm) {
            padding: $spacing-unit / 2;
            padding-top: $spacing-unit + 10px; } }

    .lightbox-img {
        max-height: 80vh; }

    .lightbox-prev {
        @include media-query($on-palm) {
            position: absolute;
            left: 0;
            padding-top: 80px; } }

    .lightbox-next {
        @include media-query($on-palm) {
            position: absolute;
            right: 0;
            padding-top: 80px; } }

    .modal-content {
        min-width: 80vw;
        max-height: 75vh;
        overflow: auto;
        background-color: $white-color;
        box-shadow: 0 5px 30px 0 rgba(#000, .8);

        .video-container {
            width: 560px;
            max-width: 100%; } }

    [data-btn~="fechar"] {
        position: absolute;
        background-color: transparent;
        top: $spacing-unit / 4;
        right: $spacing-unit;
        z-index: 9999;
        margin: 0;
        padding: 0;
        border: 0;
        line-height: 1;
        color: $grey-color-light;
        box-shadow: none;
        transform: rotate(-45deg);

        svg {
            width: 25px;
            height: 25px;
            color: inherit;

            * {
                fill: currentcolor; } } }

    &:not(:target):not(.target) {
        display: none;
        opacity: 0; }

    @include variant(transparent) {
        > * {
            background-color: transparent;
            box-shadow: none; } }

    @include variant(details-table) {
        .modal-content {
            border-left: 10px solid $compl-color;
            min-width: 70vw;
            max-width: 70vw;

            h1 {
                color: $compl-color; }

            table {
                min-width: 780px; } } }

    @include variant(warning) {
        background-color: transparent;
        bottom: unset;
        padding: $spacing-unit/2;
        color: $warning-color-dark;

        [data-btn~="fechar"] {
            top: 20px;
            right: $spacing-unit/2;

            svg {
                width: $spacing-unit/2;
                height: $spacing-unit/2;
                color: $grey-color; } }

        .modal-content {
            border-left: 10px solid $warning-color;
            box-shadow: 0 5px 30px 0 rgba(#000, .6);
            max-width: 70vw;
            padding: $spacing-unit/2 45px $spacing-unit/2 $spacing-unit/2; } }


    @include variant(danger) {
        background-color: transparent;
        bottom: unset;
        padding: $spacing-unit/2;
        color: $danger-color-dark;

        [data-btn~="fechar"] {
            top: 20px;
            right: $spacing-unit/2;

            svg {
                width: $spacing-unit/2;
                height: $spacing-unit/2;
                color: $grey-color; } }

        .modal-content {
            border-left: 10px solid $danger-color;
            box-shadow: 0 5px 30px 0 rgba(#000, .6);
            max-width: 70vw;
            padding: $spacing-unit/2 45px $spacing-unit/2 $spacing-unit/2; } }

    @include variant(success) {
        background-color: transparent;
        bottom: unset;
        padding: $spacing-unit/2;
        color: $success-color;

        [data-btn~="fechar"] {
            top: 20px;
            right: $spacing-unit/2;

            svg {
                width: $spacing-unit/2;
                height: $spacing-unit/2;
                color: $grey-color; } }

        .modal-content {
            border-left: 10px solid $success-color;
            box-shadow: 0 5px 30px 0 rgba(#000, .6);
            max-width: 70vw;
            padding: $spacing-unit/2 45px $spacing-unit/2 $spacing-unit/2; } }

    @include variant(inline) {
        [data-btn~="fechar"] {
            top: 20px;
            right: $spacing-unit/2;

            svg {
                width: $spacing-unit/2;
                height: $spacing-unit/2;
                color: $danger-color; } } }


    @include variant(terminal) {
        padding: 0;
        [data-btn~="fechar"] {
            top: 20px;
            right: $spacing-unit;
            color: $danger-color;
            line-height: $spacing-unit/3;

            svg {
                width: $spacing-unit/2;
                height: $spacing-unit/2;
                color: $danger-color;
                margin-left: $spacing-unit/2; } }

        .modal-content {
            width: 100vw;
            max-height: 100vh;
            border-radius: 0;
            padding: 0;

            [data-btn~="fechar"] {
                right: $spacing-unit/2; }

            .modal-content {
                width: auto;
                // max-height: 100vh
                // border-radius: 0
                padding: $spacing-unit/2 45px $spacing-unit/2 $spacing-unit/2; }

            .header {
                background-color: $grey-color-dark;
                color: $white-color;
                padding: $spacing-unit/2 $spacing-unit;

                h3 {
                    margin: 0; } } } }

    @include variant(slide) {
        > * {
            -webkit-animation: slide-in .6s ease-in-out forwards;
            animation: slide-in .6s ease-in-out forwards; } }

    @include variant(fast) {
        -webkit-animation-duration: .1s;
        animation-duration: .1s; }

    @include media-query($on-palm) {
        iframe {
            max-width: 220px;
            max-height: 420px; } } }

@-webkit-keyframes fade-in {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

@keyframes fade-in {
    0% {
        opacity: 0; }
    100% {
        opacity: 1; } }

@-webkit-keyframes slide-in {
    0% {
        -webkit-transform: translateY(-100%);
        opacity: 0; }
    100% {

        -webkit-transform: translateY(0);
        opacity: 1; } }

@keyframes slide-in {
    0% {
        transform: translateY(-100%);
        opacity: 0; }
    100% {
        transform: translateY(0);
        opacity: 1; } }
