
$base-font-family: 'Playfair Display';
$bold-font-family: 'Playfair Display Bold';
$soft-font-family: 'Quicksand';
$logo-font-family: 'Playfair Display';
$title-font-family: 'Playfair Display';
$base-font-size:   1em;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.75;
$medium-font-size:  $base-font-size * 0.85;
$lg-font-size:  $base-font-size * 1.15;
$base-line-height: 1.5;
$full-height: calc(100vh - 100px);

$spacing-unit:     30px;

$text-color:       #FFFAF0;
$background-color: #ffffff;
// $brand-color:      #646483
$link-color:       #43AAB8;
$title-color:      #FFD700;
// $compl-color:      #902BC3
$black-color:      #000000;
$not-delivered-color:      #FF68FF;

$brand-color:       #6867AC;
$brand-color-light: #A267AC;
$compl-color:       #CE7BB0;
$compl-color-light: #FFBCD1;

$grey-color:       #989898;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$blue-color:       #1E9FD3;
$blue-color-light: lighten($blue-color, 40%);
$blue-color-dark:  darken($blue-color, 25%);

$water-green-color:       #208194;
$water-green-color-light: lighten($water-green-color, 40%);
$water-green-color-dark:  darken($water-green-color, 25%);

// $brand-color-light: lighten($brand-color, 40%)
// $brand-color-dark:  darken($brand-color, 25%)

$danger-color:          #E55A5A;
$danger-color-dark:     darken($danger-color, 10%);

$success-color:         #FFA07A;
$success-color-dark:    darken($success-color, 50%);

$warning-color:         #FFD700;
$warning-color-dark:    darken($warning-color, 10%);

$white-color:      #ffffff;
$white-color-dark: darken($white-color, 3%);

$dark-blue:        #242041;

$on-palm:             600px;
$on-laptop:           800px;
$on-mobile:           768px;
$content-width:       1440px;
$sm-width: 800px;
$md-width: 1000px;
$lg-width: 1920px;
$full-width: 100%;
