.login-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: $spacing-unit; }

.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px; }

.login-google {
  background-color: #4285f4; }

.login div {
  margin-top: 7px; }
