
%transition {
    backface-visibility: hidden;
    // transform: translate3d(0, 0, 0)
    // transform-style: preserve-3d
    transition: .3s ease-in-out; }

body {
    font-family: $font-family; }


.container-full {
    width: 100%;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
    overflow: hidden; }
