html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth; }


body, h1, h2, h3, h4, h5, h6, p,
blockquote, pre, hr, dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  font-family: $title-font-family;
  font-weight: $base-font-weight;
  line-height: 1.1; }

@for $i from 1 through 6 {
  h#{$i} {
    font-size: $lg-font-size * 1.5 + $lg-font-size / $i;
    text-transform: none;
    font-style: normal; } }

body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color; }
//   background-color: $compl-color-light
//   background: linear-gradient(-45deg, $brand-color, $brand-color-light, $compl-color, $compl-color-light)
//   background-size: 400% 400%
//   animation: gradient 15s ease infinite
//-webkit-font-feature-settings: "kern" 1
//-moz-font-feature-settings: "kern" 1
//-o-font-feature-settings: "kern" 1
//font-feature-settings: "kern" 1
//font-kerning: normal
//box-sizing: border-box
//scroll-behavior: smooth
//*
//  box-sizing: inherit

//*:focus
//  outline: none;

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
 }  // padding: 0 $spacing-unit

.logo {
  font-family: $soft-font-family;
  letter-spacing: 4px;

  a {
    color: $white-color; } }

.z-index99 {
  z-index: 99; }

.sticky {
  position: sticky;
  top: 0; }

.login-text {
  top: 66px; }

%transition {
  backface-visibility: hidden;
  // transform: translate3d(0, 0, 0)
  // transform-style: preserve-3d
  // filter: blur(.3px)
  transition: .3s ease-in-out; }

%cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

a {
    text-decoration: none;
    @extend %transition;
    color: $title-color;
    text-shadow: 1px 1px 30px $brand-color;

    &:hover {
        color: $text-color; } }

img {
    max-width: 100%; }

h1, h2, h3, h4, h5, h6, p,
blockquote, pre, figure,
%vertical-rhythm {
  margin-top: $spacing-unit / 2;
  margin-bottom: $spacing-unit / 2; }

.wrapper {
    background: linear-gradient(-45deg, $brand-color, $brand-color-light, $compl-color, $compl-color-light);
    background-size: 400% 400%;
    box-sizing: border-box;
    // min-height: 100vh
    border: 0;
    padding: 0; }

.movingBG {
    animation: gradient 15s ease infinite; }

.staticBGDark {
    background-position: 100% 50%; }

.staticBGLight {
    background-position: 0% 50%; }

.main-content {
    min-height: $full-height; }

@keyframes gradient {
    0% {
        background-position: 0% 50%; }
    50% {
        background-position: 100% 50%; }
    100% {
        background-position: 0% 50%; } }

.seamless {
    list-style: none; }

.icon-list li {
    line-height: $spacing-unit+$spacing-unit/2; }

.icon-list li a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: $spacing-unit/6; }

.container {
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;

    &.sm {
        max-width: $sm-width; }

    &.md {
        max-width: $md-width; }

    &.lg {
        max-width: $lg-width; }

    @include media-query($on-mobile) {
      padding-right: $spacing-unit/2;
      padding-left: $spacing-unit/2; } }



.main-nav ul {
    list-style: none;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-family: $soft-font-family;
    font-size: $lg-font-size;
    text-shadow: 1px 1px 30px $brand-color;
    padding: $spacing-unit/3 0;
    @extend %transition;
    grid-gap: $spacing-unit;

    a {
        color: $white-color;
        opacity: 0.8; }

    @include media-query($on-mobile) {
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      right: 0;
      transform: translate(100%);
      width: 100vw;
      height: 100vh;
      padding: $spacing-unit/2;

      &.active {
        transform: translate(0); } } }

.all-center-v {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-height: $full-height;
    text-align: center;

    @include media-query($on-mobile) {
      flex-direction: column; } }

.all-center-h {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center; }

.all-right-h {
    display: flex;
    justify-content: flex-end;
 }    // align-content: center

.flex-direction-column {
    flex-direction: column; }

.txt-center {
    text-align: center; }

.txt-left {
    text-align: left; }

.txt-right {
    text-align: right; }

.content-sm-pd {
    padding: $spacing-unit/3 0; }

.content-md-pd {
    padding: $spacing-unit/2 0; }

.content-lg-pd {
    padding: $spacing-unit 0; }

.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%; }

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); }

.spinner:before,
.spinner:after {
  position: absolute;
  content: ''; }

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: transparent;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s; }

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: transparent;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease; }

.book-cover {
    width: $spacing-unit*5; }

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }


.close-icon {
  transform: rotate(45deg); }

footer {
    display: flex;
    justify-content: center; }
