.carousel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center; }

.carousel-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .carousel-el {
    transition: ease 1000ms;
    width: 100%;
    flex-shrink: 0;
    min-width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 100%; } } }


.thumbs {
  margin-top: $spacing-unit;
  display: flex;
  align-items: center;

  &.center {
    justify-content: center; }

  &.left {
    justify-content: flex-start; }

  &.right {
    justify-content: flex-end; }

  .DOT {
    border: 0;
    width: 20px;
    height: 20px;
    padding: 0;
    background: none;
    background-color: #fff;
    border: 1px solid $primary-color;
    cursor: pointer;
    border-radius: 100%;
    overflow: hidden;
    outline: none;
    opacity: 0.6;
    margin: 0 5px; }

  .SM-DOT {
    border: 0;
    width: 15px;
    height: 15px;
    padding: 0;
    background: none;
    background-color: transparent;
    border: 1px solid $primary-color;
    cursor: pointer;
    border-radius: 100%;
    overflow: hidden;
    outline: none;
    opacity: 0.6;
    margin: 0 5px;

    @include media-query($md) {
      width: 20px;
      height: 20px; } }

  .active {
    opacity: 1;
    background-color: $primary-color; } }

.prev-btn {
  border: 0;
  border-radius: 50%;
  background: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  transition: 0.3s ease-in-out;
  width: $spacing-unit*3;
  height: $spacing-unit*3;
  color: $primary-color; }

.next-btn {
  border: 0;
  border-radius: 50%;
  background: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  transition: 0.3s ease-in-out;
  width: $spacing-unit*3;
  height: $spacing-unit*3;
  color: $primary-color; }


.prev-btn-round {
  border: 0;
  border-radius: 100%;
  background: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  transition: 0.3s ease-in-out;
  width: $spacing-unit*2;
  height: $spacing-unit*2;
  color: $white;
  border: 1px solid $white;
  @extend %transition;
  margin-right: 10px;
  padding: 0;
  text-align: center;

  &:hover {
    color: $link-hover-color;
    border: 1px solid $link-hover-color; } }


.next-btn-round {
  border: 0;
  border-radius: 100%;
  background: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  transition: 0.3s ease-in-out;
  width: $spacing-unit*2;
  height: $spacing-unit*2;
  color: $white;
  border: 1px solid $white;
  margin-left: 10px;
  padding: 0;
  text-align: center;

  &:hover {
    color: $link-hover-color;
    border: 1px solid $link-hover-color; } }


@keyframes fade {
  0% {
    opacity: 0.8;
    transition: opacity 0.8s linear; }

  100% {
    transition: opacity 0.8s linear;
    opacity: 1; } }



@keyframes slide-left {
  0% {
    opacity: 0;
    transition: 0.8s ease-in-out;
    transform: translateX(-4%); }

  100% {
    opacity: 1;
    transition: 0.8s ease-in-out;
    transform: translateX(0%); } }



@keyframes slide-right {
  0% {
    opacity: 0.6;
    transition: 0.6s ease-in-out;
    transform: translateX(4%); }

  100% {
    opacity: 1;
    transition: 0.6s ease-in-out;
    transform: translateX(0); } }



.animation-fade {
  animation: fade 0.8s linear;
  transition: opacity 0.8s linear; }


.animation-slide-left {
  animation: slide-left 0.4s ease-in-out infinite;
  transition: 0.4s ease-in-out; }


.animation-slide-right {
  animation: slide-right 0.4s ease-in-out;
  transition: 0.4s forwards; }

.slider {
  &.reverse {
    flex-direction: row-reverse;
    text-align: right; } }
