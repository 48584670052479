.blog-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .blog-post {
        width: 48%;
        margin-bottom: $spacing-unit;

        @include media-query($on-mobile) {
            width: 100%; } } }

.cover-img {
    height: 28vh;
    border-radius: 2px;

    img {
        @extend %cover-img; } }

.social-media {
    margin: $spacing-unit 0;
    display: flex;
    gap: $spacing-unit/2;
    justify-content: center; }

.pagination {
    margin: $spacing-unit 0;
    display: flex;
    gap: $spacing-unit/2;
    justify-content: center;

    li {
        cursor: pointer;
        @extend %transition;
        line-height: $spacing-unit/2;
        list-style: none;
        font-family: $soft-font-family; } }
